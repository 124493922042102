import React, { useContext, useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "../styles/templates/accessory.scss"
import Layout from "../components/layout"
import CartContext from "../context/cartContext"
import SEO from "../components/seo"
import ReviewCarousel from "../components/ReviewCarousel"
import ProductValueProps from "../components/ProductValueProps"
import Breadcrumbs from "../components/Breadcrumbs"
import AboutProductSection from "../components/AboutProductSection"
import { colorTags } from "../services/data"
import NewButton from "../components/NewButton"
import ProductSlider from "../components/ProductSlider"
import ProductWithHubble from "../components/ProductWithHubble"
import FAQBlock from "../components/FAQBlock"
import { accessoriesFaq, accessoryValueProps, accessoriesWithHubble } from "../services/data"
import {accessoryInfo} from "../services/accessoryDictionary"
import RelatedFrames from "../components/RelatedFrames"
import WebpImage from "../components/WebpImage"

const Accessory =  ({ pageContext }) => {
  const [buttonText, setButtonText] = useState("Add Item to Cart")
  const [accessoryProduct, setAccessoryProduct] = useState({})
  const { addItemCart, setHeaderColor, setCartDisplay } = useContext(CartContext)

  const {
    title,
    productImage1,
    productImage2,
    handle,
    variantId,
    shopifyProductId,
    price,
    id,
    type,
    relatedAccessories
  } = pageContext

  const [slides, setSlides] = useState([
    productImage1,
  ])

  const accessoryInfoMeta = accessoryInfo.find(obj => {
    return obj.title.replaceAll("®", "").toLowerCase() === title.replaceAll("®", "").toLowerCase() ||
      handle === obj.value
  })

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What eye care accessories does Hubble offer?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hubble is proud to offer a wide range of eye care products and accessories including eye drops, lens care kits, eye compresses, specialized contact lens solutions and more. We’ve done the work of thinking through just about everything you need to keep your glasses and contact lenses in perfect condition and compiled them here!"
          }
        },
        {
          "@type": "Question",
          "name": "Does Hubble manufacture eyeglasses and eye care accessories?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "All the eye care accessories you’ll find on this site are from our trusted partners and hand selected by the Hubble team. Taking care of your holistic vision health has never been easier, no matter what you’re looking for."
          }
        },
        {
          "@type": "Question",
          "name": "What accessories come standard with Hubble Contact Lenses, Eyeglasses and Sunglasses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Each pair of Hubble Eyeglasses and Sunglasses include a slim, sturdy, tri-fold case and a microfiber cloth for the occasional polish. If you’re looking to purchase additional specialized accessories you can count on, you’ve come to the right place!"
          }
        },
        {
          "@type": "Question",
          "name": "Do Hubble offer free shipping?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes! All of our eye care accessories always ship free. If you have issues or questions about any of the products offered on our site, please reach out to us and we’ll make it right."
          }
        },
        {
          "@type": "Question",
          "name": "What's the best way to clean my Hubble eyeglass lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can clean your lenses with warm water and a small drop of mild dish soap. Then, dry with a clean, soft cotton cloth, like the microfiber lens cloth that came with your purchase. You can also use the Frames by Hubble lens cloth to wipe off light smudges on the regular."
          }
        }  ]
    },
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": `${title}"`,
      "image": productImage1,
      "description": accessoryInfoMeta?.metaDescription ,
      "brand": {
        "@type": "Thing",
        "name": "Hubble"
      },
      "logo": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "offers": {
        "@type": "Offer",
        "url": `https://hubblecontacts.com/accessory/${handle}`,
        "availability": "https://schema.org/InStock",
        "price": price,
        "priceCurrency": "USD"
      },
      "model": `${title}`,
      "color": `N/A`,
    },
    {
      "@context": "https://schema.org",
      "@url": `https://hubblecontacts/accessory/${handle}`,
      "@type": "WebPage",
      "name": title,
      "description": accessoryInfoMeta?.metaDescription,
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]

  useEffect(() => {
    const currentItem = accessoryInfo.find(obj => {
      return obj.title.toLowerCase() === title.toLowerCase() ||
        handle === obj.value
    })
    setAccessoryProduct(currentItem)
    setHeaderColor("blue")

    // add productImage2 to slides array if it exists in accessoryImgDictionary
    if(productImage2){
      setSlides([...slides, productImage2])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[title])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id:        shopifyProductId,
              item_name:      title,
              currency:       'USD',
              discount:       0,
              index:          0,
              item_brand:     'Hubble',
              item_category: "accessories",
              item_category2: 'contact accessories',
              item_category3: 'N/A',
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: `Accessories`,
              item_variant:   'N/A',
              price: parseFloat(price),
            }
          ]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleAddToCart = () => {
    setButtonText("Item Added To Cart!")
    // open cart here
    const newAccessory = {
      id,
      variant_id: variantId,
      sku: `ACCESSORY${id}`,
      image: productImage1,
      product_id: shopifyProductId,
      title,
      price: price,
      type
    }
    setCartDisplay(true)
    return addItemCart(newAccessory)
  }

  const relatedProducts = relatedAccessories.edges.map(
    (edge) => ({...edge.node, type:"accessory"})
  )

  return (
    <Layout className="accessory">
      <SEO title={accessoryInfoMeta?.metaTitle} description={accessoryInfoMeta?.metaDescription} schemaMarkup={schemaMarkup}/>
      <div className="top-section">
        <NewButton
          label={accessoryInfoMeta?.soldOut ? "Sold Out" : buttonText}
          color={colorTags[type]}
          onClick={handleAddToCart}
          disabled={accessoryInfoMeta?.soldOut}
          className={'mobile-only'} />
        <div className="image-section">
          <ProductSlider slides={slides} type={type} containImage product={accessoryProduct} currentVariant={""} />
        </div>
        <div className="product-section">
          <div className="product-details">
            <h1 className="title">{accessoryInfoMeta?.altTitle || title}</h1>
            <p className="subheader">{accessoryProduct?.subheader}</p>
            <p className="price">
              ${Number(price).toFixed(2)}
            </p>
            <div className="descriptor-container">
              <div className="descriptor">
                <strong className="label">BRAND</strong>
                <p className="value">
                    {accessoryInfoMeta?.brand}
                </p>
              </div>
            </div>
            <NewButton
              label={accessoryInfoMeta?.soldOut ? "Sold Out" : buttonText}
              color={colorTags[type]}
              onClick={handleAddToCart}
              className={'desktop-only w-100'}
              disabled={accessoryInfoMeta?.soldOut}
            />
          </div>
        </div>
      </div>
      <ProductValueProps props={accessoryValueProps} type={type} />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/eye-care-accessories", label: "Eye Care Accessories" },
              { label: title },
            ]}
          />
        </div>
        <AboutProductSection title={title} type={type} description={accessoryProduct?.description} bullet_points={accessoryProduct?.points} noImage />
        <ProductWithHubble type={type} items={accessoriesWithHubble} />
        <div className="grid-section lifestyle-shot">
          <WebpImage
            fileName="Pages/AccessoryPDP/Hubble-Accessories-PDP-Inset-Lifestyle-Image.jpg"
            alt="Women starting her day putting in her Hubble Contacts"
          />
        </div>
        <FAQBlock faqList={accessoriesFaq} />
        <ReviewCarousel color={"blue"} page="accessory" />

        {
          relatedProducts?.length > 0 &&
          <RelatedFrames
            relatedFrames={relatedProducts}
            type={type}
            linkTo="/contact-lenses"
          />
        }

      </div>
    </Layout>
  )
}
export default Accessory
